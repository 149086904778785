<template>
  <div ref="layoutViewRef" id="tester-portal">
    <Layout
      v-if="loginUserData.userId"
      :loginUserData="loginUserData"
      :permissionData="permissionData"
      :userLevelList="userLevelList"
      :sourceLineList="sourceLineList"
      :selectedUserLevel="selectedUserLevel"
      :selectedSourceLine="selectedSourceLine"
      :loginPlayerData="loginPlayerData"
      :requestResponseData="requestResponseData"
      :onChangeSelectedUserLevel="onChangeSelectedUserLevel"
      :onChangeSelectedSourceLine="onChangeSelectedSourceLine"
    >
      <RouterView
        :loginUserData="loginUserData"
        :permissionData="permissionData"
        :selectedUserLevel="selectedUserLevel"
        :selectedSourceLine="selectedSourceLine"
        :loginPlayerData="loginPlayerData"
        :requestResponseData="requestResponseData"
        :ratioData="ratioData"
        :onChangeLoginUserData="onChangeLoginUserData"
        :onChangeLoginPlayerData="onChangeLoginPlayerData"
        :onChangePermissionData="onChangePermissionData"
      />
    </Layout>
    <RouterView
      v-else
      :loginUserData="loginUserData"
      :onChangeLoginUserData="onChangeLoginUserData"
    />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  toRaw,
  onMounted,
} from 'vue'

import { useRouter, useRoute } from 'vue-router'
import { jwtDecode } from 'jwt-decode'
import moment from 'moment'

import Layout from '@/components/TesterPortal/Layout'

import { refreshAuthToken } from '@/api/testerportal/login'
import { getPermissions, getSourceLines, getCurrencies } from '@/api/testerportal'

import { USER_LEVEL_LIST_BY_PERMISSION } from '../constants/testerPortal'

export default {
  name: 'TestPortal',
  components: {
    Layout,
  },
  setup() {
    const emptyObject = obj => Object.keys(obj).forEach(key => delete obj[key])

    const router = useRouter()
    const route = useRoute()
    const layoutViewRef = ref()

    const selectedUserLevel = ref('')
    const userLevelList = ref([])

    const selectedSourceLine = reactive({})
    const sourceLineList = reactive([])

    const loginUserData = reactive({})
    const loginPlayerData = reactive({})
    const ratioData = ref([])
    const permissionData = reactive({})

    const token = localStorage.getItem('token')
    let decodedToken = {}
    if (token) {
      const now = moment().unix()
      decodedToken = jwtDecode(token)
      const isTokenExpired = decodedToken.exp <= now
      const lastActivity = localStorage.getItem('lastActivity')
      const isIdle = !lastActivity || (now - lastActivity > 30 * 60)
      decodedToken = isIdle || isTokenExpired ? {} : decodedToken
      // console.log('decodedToken', decodedToken, isIdle)
      Object.assign(loginUserData, decodedToken)
    }

    const requestResponseData = reactive({
      url: '',
      request: '',
      response: '',
    })

    const getCurrencyAndRatio = async () => {
      console.log('getCurrencyAndRatio agentId', selectedSourceLine.agentId)
      if (!selectedSourceLine.agentId) return

      const reqData = sourceLineList.reduce((acc, { source, agentId }) => {
        if (!acc[source]) acc[source] = []
        acc[source].push(agentId)
        return acc
      }, {})

      const { data, ...currencyDataBySourceAgent } = await getCurrencies(reqData)

      sourceLineList.forEach(({ source, agentId }, idx) => {
        const currency = currencyDataBySourceAgent[source][agentId]
        sourceLineList[idx].currency = currency
        if (selectedSourceLine.source === source && selectedSourceLine.agentId === agentId) {
          selectedSourceLine.currency = currency
        }
      })

      ratioData.value.length = 0
      ratioData.value.push(...data.map((d) => {
        let equivalentInTHB
        if (d.realCurrency) {
          const ratioArr = d.ratio.split(':')
          const computredRatio = ratioArr[0] / ratioArr[1]
          equivalentInTHB = Number((computredRatio * d.rate * 1000).toFixed(3))
        }
        return {
          ...d,
          equivalentInTHB,
        }
      }))
    }

    const onChangeSelectedSourceLine = (val) => {
      console.log('onChangeSelectedSourceLine', val)
      if (!val) return

      let sourceLineData = val
      if (typeof val === 'string') { // used in Layout
        sourceLineData = sourceLineList.find(({ sourceLineId }) => sourceLineId === val)
      }
      console.log('onChangeSelectedSourceLine sourceLineData', sourceLineData)
      Object.assign(selectedSourceLine, sourceLineData)
    }

    const onChangeSelectedUserLevel = async (userLevel) => {
      console.log('onChangeSelectedUserLevel', userLevel)
      if (!userLevel) return
      selectedUserLevel.value = userLevel
      let { data: sourceLinesData } = await getSourceLines(userLevel)
      console.log('getSourceLines data', sourceLinesData)

      if (sourceLinesData) {
        sourceLinesData = sourceLinesData.sort((a, b) => {
          if (a.sourceLineId.startsWith('uat') && !b.sourceLineId.startsWith('uat')) return -1
          if (!a.sourceLineId.startsWith('uat') && b.sourceLineId.startsWith('uat')) return 1
          return a.sourceLineId.localeCompare(b.sourceLineId)
        })

        sourceLineList.length = 0
        sourceLineList.push(...sourceLinesData)
        await onChangeSelectedSourceLine(sourceLinesData[0])
        await getCurrencyAndRatio()
      }
    }

    const onChangePermissionData = (data) => {
      console.log('onChangePermissionData', data)
      Object.assign(permissionData, data)
    }

    const onChangeLoginUserData = (data) => {
      console.log('onChangeLoginUserData', data)
      if (data) {
        Object.assign(loginUserData, data)
      } else {
        emptyObject(loginUserData)
      }
    }

    const onChangeLoginPlayerData = (data) => {
      console.log('onChangeLoginPlayerData', data)
      if (data) {
        Object.assign(loginPlayerData, data)
      } else {
        emptyObject(loginPlayerData)
      }
    }

    watch(loginUserData, async (newVal, oldVal) => {
      console.log('watch loginUserData', toRaw(newVal), toRaw(oldVal), selectedUserLevel.value)
      if (!newVal.userId) selectedUserLevel.value = undefined
      if (!selectedUserLevel.value && newVal.userId) {
        if (!Object.keys(permissionData).length) {
          const { data } = await getPermissions()
          const permissionDataMap = data.reduce((acc, { permission, allowedUserLevels }) => {
            acc[permission] = allowedUserLevels
            return acc
          }, {})

          onChangePermissionData(permissionDataMap)
          console.log('route.name', route.name)
          console.log('permissionDataMap', permissionDataMap)
          if (!['TesterPortalHome', 'TesterPortalLogin'].includes(route.name)
            && !permissionDataMap[`${route.name}_View`].includes(newVal.permission)) {
            router.replace('/testerportal')
          }
        }

        userLevelList.value = USER_LEVEL_LIST_BY_PERMISSION[newVal.permission]
        selectedUserLevel.value = userLevelList.value[0] // eslint-disable-line prefer-destructuring
        await onChangeSelectedUserLevel(selectedUserLevel.value)
      }
    }, { immediate: true })

    onMounted(async () => {
      layoutViewRef.value.addEventListener('click', async () => {
        const now = moment().unix()
        const lastActivity = localStorage.getItem('lastActivity')

        // console.log('--- CLICK ---',
        //   'loginUserData.exp:', loginUserData.exp ? moment(loginUserData.exp * 1000).format() : loginUserData.exp, `(${loginUserData.exp})`,
        //   'lastActivity:', lastActivity ? moment(lastActivity * 1000).format() : null, `(${lastActivity})`,
        //   'Now:', moment(now * 1000).format(), `(${now})`)

        if (loginUserData.exp) {
          const isIdle = lastActivity && (now - lastActivity > 30 * 60)
          if (isIdle) {
            const isTokenExpired = loginUserData.exp <= now
            const isTokenAlmostExpired = loginUserData.exp - now < 30 * 60
            let data
            if (!isTokenExpired && isTokenAlmostExpired) ({ data } = await refreshAuthToken())
            onChangeLoginUserData(data)
            // console.log(1111, 'isIdle')
            localStorage.removeItem('lastActivity')
            alert('You have been logged out of your account') // eslint-disable-line no-alert
            router.push('/testerportal/login')
          } else { // lastActivity is in 30m or no lastActivity
            // console.log(2222, 'lastActivity is in 30m or no lastActivity')
            localStorage.setItem('lastActivity', now)
          }
        }
      })
    })

    return {
      selectedUserLevel,
      userLevelList,
      selectedSourceLine,
      sourceLineList,
      layoutViewRef,
      loginUserData,
      loginPlayerData,
      requestResponseData,
      ratioData,
      permissionData,
      onChangeLoginUserData,
      onChangeSelectedUserLevel,
      onChangeSelectedSourceLine,
      onChangeLoginPlayerData,
      onChangePermissionData,
    }
  },
}
</script>

<style>
.ant-table-cell .ant-divider-vertical {
  display: none;
}

.ant-table-cell a + .ant-divider-vertical:has(+ a), .ant-table-cell a + .ant-divider-vertical:has(+ span), .ant-table-cell span + .ant-divider-vertical:has(+ a), .ant-table-cell span + .ant-divider-vertical:has(+ span) {
  display: inline-block;
}
</style>
